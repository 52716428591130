<template>
  <div class="m-4">
    <div>
      <span>{{ title }}</span>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="chart-container">
          <apexchart
            width="350"
            height="350"
            type="donut"
            :options="chartOptionsAverage"
            :series="chartSeriesAverage"
          ></apexchart>
          <div class="details">
            <div>
              <strong>NC:</strong> Average: {{ formatNumber(data.nc.average) }}
            </div>
            <div>
              <strong>NP:</strong> Average: {{ formatNumber(data.np.average) }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="chart-container">
          <apexchart
            width="350"
            height="350"
            type="donut"
            :options="chartOptionsCount"
            :series="chartSeriesCount"
          ></apexchart>
          <div class="details">
            <div>
              <strong>NC:</strong> Count: {{ formatNumber(data.nc.count) }}
            </div>
            <div>
              <strong>NP:</strong> Count: {{ formatNumber(data.np.count) }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="chart-container">
          <apexchart
            width="350"
            height="350"
            type="donut"
            :options="chartOptionsTotal"
            :series="chartSeriesTotal"
          ></apexchart>
          <div class="details">
            <div>
              <strong>NC:</strong> Total: {{ formatNumber(data.nc.total) }}
            </div>
            <div>
              <strong>NP:</strong> Total: {{ formatNumber(data.np.total) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'DonutCharts',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({
        nc: { total: 0, count: 0, average: 0 },
        np: { total: 0, count: 0, average: 0 },
      }),
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartOptionsAverage: {
        labels: ['NC Average', 'NP Average'],
        legend: {
          position: 'bottom',
        },
      },
      chartSeriesAverage: [1, 1],

      chartOptionsCount: {
        labels: ['NC Count', 'NP Count'],
        legend: {
          position: 'bottom',
        },
      },
      chartSeriesCount: [1, 1],

      chartOptionsTotal: {
        labels: ['NC Total', 'NP Total'],
        legend: {
          position: 'bottom',
        },
      },
      chartSeriesTotal: [1, 1],
    };
  },
  watch: {
    data: {
      handler() {
        this.updateCharts();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateCharts() {
      if (this.data && this.data.nc && this.data.np) {
        this.chartSeriesAverage = [this.data.nc.average || 1, this.data.np.average || 1];
        this.chartSeriesCount = [this.data.nc.count || 1, this.data.np.count || 1];
        this.chartSeriesTotal = [this.data.nc.total || 1, this.data.np.total || 1];
      } else {
        console.error('Data structure is incorrect or missing.');
      }
    },
    formatNumber(value) {
      return value.toFixed(0);
    },
  },
  mounted() {
    this.updateCharts();
  },
};
</script>

<style scoped>
.chart-container {
  margin-bottom: 20px;
}
.details {
  margin-top: 10px;
}
</style>