<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card
          header-tag="header"
          :footer="`Page ${currentPage} of ${totalPage}`"
          footer-tag="footer"
          class="mt-3"
        >
          <b-row class="mb-3">
            <b-col md="6">
              <b-form-group label="Filter by Patient Name">
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Enter patient name"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Records per page">
                <b-form-select v-model="perPage" :options="perPageOptions">
                  <template #first>
                    <option disabled value="">Select number of records</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            :items="pagedAppointments"
            :fields="fields"
            responsive="sm"
            bordered
            hover
            class="mb-3"
            :sort-by.sync="sortBy" 
            :sort-desc.sync="sortDesc" 
            @sort-changed="onSortChange" 
          ></b-table>
          <div class="d-flex justify-content-between align-items-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredAppointments.length"
              :per-page="perPage"
              align="center"
              class="mb-0"
            />
            <span class="ml-3">Total Records: {{ filteredAppointments.length }}</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    appointment: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
      sortBy: 'id',
      sortDesc: false,
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'facilities.name', label: 'Facilities Name', sortable: true },
        { key: 'patients.name', label: 'Name', sortable: true },
        { key: 'patients.lastname', label: 'Last name', sortable: true },
        { key: 'cash', label: 'Cash', sortable: true },
        { key: 'ajustment', label: 'Adjustment', sortable: true },
        { key: 'total', label: 'Total', sortable: true },
        { key: 'type_membership', label: 'Type visit', sortable: true },
        { key: 'reason_visit', label: 'Reason for Visit', sortable: true },
        { key: 'visit_date', label: 'Visit Date', sortable: true },
      ],
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' }
      ]
    }
  },
  computed: {
    filteredAppointments() {
      return this.appointment.filter(app => 
        app.patients.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    pagedAppointments() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.filteredAppointments.slice(startIndex, endIndex);
    },
    totalPage() {
      return Math.ceil(this.filteredAppointments.length / this.perPage);
    }
  },
  methods: {
    onSortChange(ctx) {
      this.sortBy = ctx.sortBy; 
      this.sortDesc = ctx.sortDesc; 
    }
  }
}
</script>

<style scoped>
.b-card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.b-table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px;
}
.b-pagination {
  margin-top: 10px;
}
</style>