<template>
  <b-container fluid>
    <b-card header="Membership List" footer-tag="footer" :footer="`Page ${currentPage} of ${totalPages}`">
      <b-row class="mb-3">
        <b-col md="6">
          <b-form-group label="Filter by Patient Name">
            <b-form-input
              v-model="searchQuery"
              placeholder="Enter patient name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Records per page">
            <b-form-select v-model="perPage" :options="perPageOptions">
              <template #first>
                <option disabled value="">Select number of records</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table 
        :items="pagedMembership"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        bordered
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @sort-changed="onSortChange"
      >
        <template #cell(patient)="data">
          <div class="d-flex align-items-center">
            <!-- <b-img thumbnail :src="data.item.patient.avatar" alt="Avatar" width="50" height="50" class="mr-3"></b-img> -->
            <div>{{ data.item.patient.name }} {{ data.item.patient.lastname }}</div>
          </div>
        </template>
      </b-table>
      <b-pagination 
        v-model="currentPage"
        :total-rows="membership.length"
        :per-page="perPage"
        align="center"
        class="mt-3"
      />
    </b-card>
  </b-container>
</template>

<script>
export default {
  props: {
    membership: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
      sortBy: 'id',
      sortDesc: false,
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'patient', label: 'Patient', sortable: true },
        { key: 'count_visits_memberships', label: 'Count visits', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'end_date', label: 'End Date', sortable: true },
        { key: 'memberships_parameters.name', label: 'Membership Name', sortable: true },
        { key: 'memberships_parameters.price', label: 'Price', sortable: true },
        { key: 'total', label: 'Total', sortable: true },
        { key: 'cash', label: 'Cash', sortable: true },
        { key: 'cc', label: 'Credit Card', sortable: true }
      ],
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' }
      ]
    }
  },
  computed: {
    filteredMembership() {
      return this.membership.filter(m => 
        `${m.patient.name} ${m.patient.lastname}`.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    pagedMembership() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.filteredMembership.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredMembership.length / this.perPage);
    }
  },
  methods: {
    onSortChange(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    }
  }
}
</script>

<style scoped>
.table {
  margin-bottom: 0;
}
.pagination {
  justify-content: center;
}
</style>