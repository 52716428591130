import { userAxios } from '../../index'

const membershipDashboard = async data => {
  try {
    return await userAxios
      .post(`insurance/memberships/dashboard`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  membershipDashboard,
}
