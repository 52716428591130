<template>
  <div class="container">
    <div class="col-12 d-flex justify-content-center m-2">
      <h5 class="mb-4">Patients Per Day</h5>
    </div>
    <div>
      <div class="col-12 d-flex justify-content-center m-2">
        <apexchart
          width="1000"
          height="428"
          type="bar"
          :options="options"
          :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    patientsPerDayNc: {
      type: Array,
      required: true,
    },
    patientsPerDayNp: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          style: {
            fontSize: '12px',
            colors: ['#fff'], // Número en blanco
          },
          background: {
            enabled: false, // Fondo transparente
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM yyyy',
              day: 'dd MMM',
              hour: 'dd MMM HH:mm',
            },
          },
          tickAmount: 6,
        },
        yaxis: {
          title: {
            text: 'Count',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
          },
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
      },
      series: [],
    }
  },
  mounted() {
    this.prepareChartData()
  },
  watch: {
    patientsPerDayNc: {
      handler() {
        this.prepareChartData()
      },
      deep: true,
    },
    patientsPerDayNp: {
      handler() {
        this.prepareChartData()
      },
      deep: true,
    },
  },
  methods: {
    prepareChartData() {
      const combinedData = {}

      // Obteniendo la fecha actual
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0') // Mes actual (0-11, por eso sumamos 1)
      const currentDay = String(currentDate.getDate()).padStart(2, '0') // Día actual
      const formattedCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`

      this.patientsPerDayNc.forEach(item => {
        // Usar la fecha actual si la fecha no está presente
        const year = item.year || currentYear
        const month = item.month ? item.month.padStart(2, '0') : currentMonth
        const day = item.day ? item.day.padStart(2, '0') : currentDay
        const date = `${year}-${month}-${day}`

        if (!combinedData[date]) {
          combinedData[date] = { nc: 0, np: 0 }
        }
        combinedData[date].nc = item.count
      })

      this.patientsPerDayNp.forEach(item => {
        // Usar la fecha actual si la fecha no está presente
        const year = item.year || currentYear
        const month = item.month ? item.month.padStart(2, '0') : currentMonth
        const day = item.day ? item.day.padStart(2, '0') : currentDay
        const date = `${year}-${month}-${day}`

        if (!combinedData[date]) {
          combinedData[date] = { nc: 0, np: 0 }
        }
        combinedData[date].np = item.count
      })

      const ncData = []
      const npData = []

      for (const [date, counts] of Object.entries(combinedData)) {
        ncData.push({ x: new Date(date).getTime(), y: counts.nc })
        npData.push({ x: new Date(date).getTime(), y: counts.np })
      }

      this.series = [
        {
          name: 'NC',
          data: ncData,
        },
        {
          name: 'NP',
          data: npData,
        },
      ]
    },
  },
}
</script>

<style>
/* Estilo opcional */
</style>
